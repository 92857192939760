@import 'src/styles/tokens';

.rules-accordion {
  .mat-expansion-panel-body {
    padding: 0;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border: none;
  }

  td.mat-cell, td.mat-footer-cell {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .action-background {
    height: 100% !important;
    top: 1px !important;
  }
}
.dynamic-table tr.mat-header-row {
  height: 64px !important;
}

.rules-menu * {
  color: $color-neutral-60;
}
